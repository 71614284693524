import React from 'react';

import Layout from '../components/Layout';

import Scroll from '../components/Scroll';

import logo from '../assets/img/logo.png';
import pic1 from '../assets/images/pic01.jpg';
import pic2 from '../assets/images/pic02.jpg';
import pic3 from '../assets/images/pic03.jpg';
import pic5 from '../assets/images/pic05.jpg';

const IndexPage = () => (
    <Layout>
        <section id="banner">
            <div className="inner">
                <img className="logo-banner" src={logo} alt="Robin Schiel Logo"/>
            </div>
            <Scroll type="id" element="one">
                <a href="#one" className="more">
                    Mehr erfahren
                </a>
            </Scroll>
        </section>

        <section id="one" className="wrapper style3 special">
            <div className="inner">
                <header className="major">
                    <h2>
                        Herzlich Willkommen!
                    </h2>
                    <p>
                        Es freut mich, dass Sie sich für eine Psychotherapie in meiner Praxis interessieren. Ich möchte
                        ihnen auf dieser Seite gerne einige Informationen über die psychotherapeutische Behandlung,
                        meine Person und Kontaktmöglichkeiten bereitstellen.
                    </p>
                    <p>
                        In meiner Praxis biete ich Psychotherapie mit dem Schwerpunkt Verhaltenstherapie an und freue
                        mich, wenn Sie Kontakt mit mir aufnehmen. In einem ersten Gespräch können wir gerne alle
                        organisatorischen Fragen hinsichtlich der Kostenübernahme und dem weiteren Vorgehen klären.
                    </p>
                </header>
            </div>
        </section>

        <section id="two" className="wrapper alt style2">
            <section className="spotlight">
                <div className="image">
                    <img src={pic2} alt=""/>
                </div>
                <div className="content">
                    <h2>
                        Behandlungsspektrum
                    </h2>
                    <p>
                        Meine Therapierichtung (Fachkunde) als Psychologischer Psychotherapeut ist die Kognitive
                        Verhaltenstherapie, die neben der Psychoanalyse, der Tiefenpsychologisch fundierten
                        Psychotherapie und der Systemischen Therapie zu den wissenschaftlich fundierten und von der
                        gesetzlichen und privaten Krankenkasse anerkannten Verfahren gehören und deren Kosten
                        grundsätzlich übernommen werden.
                    </p>
                </div>
            </section>
            <section className="spotlight">
                <div className="image">
                    <img src={pic3} alt=""/>
                </div>
                <div className="content">
                    <p>
                        Für mich ist es wichtig den Menschen in all seinen Facetten und Bedürfnissen kennenzulernen und
                        zu erleben. Hierfür können wir uns in der Psychotherapie ausreichend Zeit lassen.
                    </p>
                    <p>Scheuen Sie sich nicht, sich zu melden, um einen Erstkontakt zu vereinbaren.</p>
                </div>
            </section>
        </section>

        <section id="three" className="wrapper style1 special">
            <div className="inner">
                <header>
                    <h4>Zu den möglichen Problembereichen gehören</h4>
                </header>
                <ul className="features">
                    <li className="icon solid fa-couch">
                        <h3>Depressive und andere affektive (emotionale) Störungen</h3>
                        <p>
                            &bull;&nbsp;Bipolare Störungen<br/>
                            &bull;&nbsp;Manische Episoden<br/>
                            &bull;&nbsp;Depressive Episoden
                        </p>
                    </li>
                    <li className="icon solid fa-notes-medical">
                        <h3>Angsterkrankungen</h3>
                        <p>
                            &bull;&nbsp;Panikstörungen<br/>
                            &bull;&nbsp;Soziale Phobie<br/>
                            &bull;&nbsp;Agoraphobie<br/>
                            &bull;&nbsp;Generalisierte Angststörungen
                        </p>
                    </li>
                    <li className="icon solid fa-brain">
                        <h3>Zwangsstörungen</h3>
                        <p>
                            &bull;&nbsp;Zwangshandlungen<br/>
                            &bull;&nbsp;Zwangsgedanken
                        </p>
                    </li>
                    <li className="icon solid fa-briefcase-medical">
                        <h3>Anpassungsreaktionen/ schwere&#160;Belastungen</h3>
                        <p>
                            &bull;&nbsp;bei schweren Erkrankungen<br/>
                            &bull;&nbsp;nach kritischen Lebensereignissen
                        </p>
                    </li>
                    <li className="icon solid fa-heartbeat">
                        <p>
                            &bull;&nbsp;Essstörungen<br/>
                            &bull;&nbsp;Schlafstörungen<br/>
                            &bull;&nbsp;Abhängigkeitserkrankungen<br/>
                            &bull;&nbsp;Persönlichkeitsstörungen
                        </p>
                    </li>
                    <li className="icon solid fa-user-injured">
                        <p>
                            &bull;&nbsp;Somatoforme Störungen<br/>
                            &bull;&nbsp;Störungen der Impulskontrolle<br/>
                            &bull;&nbsp;Sexuelle Störungen
                        </p>
                    </li>
                </ul>
            </div>
        </section>

        <section id="four" className="wrapper alt style2">
            <section className="spotlight">
                <div className="image">
                    <img src={pic1} alt=""/>
                </div>
                <div className="content">
                    <h2>
                        Über mich
                    </h2>
                    <p>
                        Um Ihnen einen Einblick über meinen beruflichen Werdegang zu geben, habe ich hier einige
                        Stationen meiner Tätigkeiten aufgelistet. Natürlich kann dies nicht den Eindruck eines
                        persönlichen Gesprächs ersetzen, weswegen ich mich freue, Sie persönlich kennenzulernen.
                    </p>
                </div>
            </section>
            <section className="spotlight">
                <div className="image">
                    <img src={pic5} alt=""/>
                </div>
                <div className="content">
                    <ul>
                        <li>Supervisor (Anerkennung durch die Landespsychotherapeutenkammer Baden-Württemberg)</li>
                        <li>Dozent am Zentrum für Psychologische Psychotherapie in Heidelberg</li>
                        <li>Ausbildung zum Psychologischen Psychotherapeuten in Heidelberg</li>
                        <li>Studium der Psychologie mit Abschluss Diplom in Heidelberg</li>
                        <li>Studium der Sozialpädagogik mit Abschluss Diplom in Dortmund</li>
                        <li>Tätigkeit im Universitätsklinikum Heidelberg (Klinik für Allgemeine Innere Medizin und
                            Psychosomatik, Klinik für Strahlentherapie)
                        </li>
                        <li>Weiterbildung Psychosoziale Onkologie (Psychoonkologie)</li>
                        <li>Diverse wissenschaftliche Tätigkeiten an der Universität Heidelberg und dem
                            Universitätsklinikum
                        </li>
                    </ul>
                </div>
            </section>
        </section>

        <section id="five" className="wrapper style3 special">
            <div className="inner">
                <header>
                    <img className="contact-banner" src={logo} alt="Robin Schiel Logo"/>
                    <h2>Kontakt</h2>
                    <p>
                        Dipl. Psych. / Dipl. Soz.-Päd. Robin Schiel<br/>Psychologischer Psychotherapeut (VT)
                    </p>
                    <p>
                        Bergheimer Str. 89/1<br/>69115 Heidelberg
                    </p>
                    <p>
                        Tel: 06221 - 90 336 41<br/>mail&#160;<span className="icon solid fa-at">&#160;</span>robinschiel.de
                    </p>
                    <p>
                        Kontaktieren Sie mich gerne per Mail, oder rufen Sie mich an. Ich melde mich so schnell wie
                        möglich bei Ihnen zurück.
                    </p>
                    <p>
                        Ich möchte Sie noch gerne darauf hinweisen, dass sich die Praxis im dritten Stock eines Altbaus,
                        ohne Aufzug befindet. Es ist also keine Barrierefreiheit gegeben.
                    </p>
                </header>
            </div>
        </section>
    </Layout>
);

export default IndexPage;
